import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card, Segment, Grid, Icon } from "semantic-ui-react"
import GoldenMilkImg from "../images/1.jpeg"
import GoldenMilkImg2 from "../images/2.jpeg"
import GoldenMilkImg3 from "../images/3.jpeg"

const SinglePostPage = () => {
  return (
    <Layout>
      <SEO title="Golden Milk | My Food Book " />

      <div style={{ margin: "100px 0" }}>
        <Card
          className="single-post-page-card"
          style={{ width: "90vw", maxWidth: "900px" }}
          image={GoldenMilkImg}
          centered
          header={<h1 style={{ textAlign: "center" }}>Golden Milk</h1>}
          meta={
            <div>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <div>
                    By : <b>Nibedita Satpathy</b>
                  </div>
                  <div>
                    Published On: <b>05 May, 2020</b>
                  </div>
                </div>
              </Segment>
              <Segment>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    color: "grey",
                  }}
                >
                  <div>
                    Time to prepare : <b>20 Min</b>
                  </div>
                  <div>
                    Servings : <b> 4 Persons</b>
                  </div>
                </div>
              </Segment>
              <br />
            </div>
          }
          description={
            <div>
              <Segment>
                <h2>Ingrediants</h2>
                <div>
                  1. 1 cup whole milk or any milk of your choice.
                  <br />
                  2. 3/4 tsp ground turmeric.
                  <br />
                  3. 1/2 inch ginger.
                  <br />
                  4. 1/4 tsp ground green cardamom .<br />
                  5. 1 pinch of ground clove (optinal)
                  <br />
                  6. pinch of ground black pepper (optional).
                  <br />
                  7. 1 spoon honey or any sweetener of your choice (sugar,maple
                  syrup, etc).
                  <br />
                </div>
              </Segment>
              <Segment style={{ fontSize: "18px" }}>
                <h2>Procedure</h2>
                <p>
                  1. Add milk to a saucepan on medium heat .when its little
                  lukewarm add ground turmeric and give it a nice whisk.
                  <br />
                  2. Add all the spices to the milk and let the spices blend in
                  milk till a boil .
                  <br />
                  3. Add the swetener and whisk it all together in simmer for 2
                  more minutes.
                  <br />
                  4. Pour the mixture through strainer and consume it while
                  still warm.
                </p>
              </Segment>
              <Segment>
                <h2>Dish Gallery</h2>
                <Grid
                  columns={3}
                  centered
                  padded="vertically"
                  celled="internally"
                  stackable
                >
                  <Grid.Row>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg2}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column fluid>
                      <img
                        src={GoldenMilkImg3}
                        alt="dish"
                        style={{
                          width: "100%",
                          height: "250px",
                          objectFit: "cover",
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Segment>
              <Segment>
                <h2>Share</h2>
                <div>
                  <Icon
                    circular
                    size="large"
                    color="blue"
                    name="facebook square"
                  />
                  <Icon circular size="large" color="violet" name="linkedin" />
                  <Icon circular size="large" color="teal" name="twitter" />
                  <Icon circular size="large" color="green" name="whatsapp" />
                </div>
              </Segment>
            </div>
          }
        />
      </div>
    </Layout>
  )
}

export default SinglePostPage
